import React from "react";

//	'https://www.pilatoartscape.com/wp-content/gallery/stone-wall/Detail-of-Dry-Stone-Wall-Westchester-NY.jpg';
//	https://us.123rf.com/450wm/kartos/kartos1210/kartos121000101/16025088-construction-masonry-cement-mortar-tools-on-white.jpg?ver=6

import hero_image from './hero_image.01.jpg';

export default function({height}) {
  return (
		<div style={{
			height:	height,
			width: '100%',
			backgroundImage: "url('"+hero_image+"')",
			backgroundPosition: '50% 50%',
			backgroundSize: 'cover'
		}}
		>
		</div>
	);
}
